loadjs.ready(["jquery"], {
  success: function () {
    // escape button closes mobile menu
    $(document).keyup(function (e) {
      if (e.keyCode === 27) {
        $("body").removeClass("offcanvas-is-visible");
        $("html").removeClass("scroll-locked");
        $(".nav-desktop .nav-dropdown").removeClass("active");
      }
    });

    // close dropdown menus on outside of menu click
    $(document).on('click', function(e){
      // console.log('e.target', $(e.target));
      // console.log('parent', $(e.target).parent());
      if (!$(e.target).closest('.nav-dropdown.active').length && $(e.target).parent('.dropdown-wrapper')) {
        $(".nav-desktop .nav-dropdown").removeClass("active");
      }
    });

    $(document).ready(function () {
      // Mobile slide-out nav
      $(".menu-btn").on("click", function (e) {
        $("body").toggleClass("offcanvas-is-visible");
        $("html").toggleClass("scroll-locked");
        $(".offcanvas-container").focus();

        $(this).toggleClass("is-active");
        if ($(this).attr("aria-expanded") === "false") {
          $(this).attr("aria-expanded", "true");
        } else {
          $(this).attr("aria-expanded", "false");
        }
        e.stopPropagation();
      });

      // mobile menu close button
      $(".menu-trigger.trigger-close").on("click", function () {
        $("body").removeClass("offcanvas-is-visible");
        $("html").removeClass("scroll-locked");
      });

      $(document).click(function (e) {
        var container = $(".offcanvas-container");
        if (!container.is(e.target) && !container.has(e.target).length) {
          $("body").removeClass("offcanvas-is-visible");
          $("html").removeClass("scroll-locked");
        }
      });

      var mq = window.matchMedia("(min-width: 53em)");

      // desktop nav
      if (mq.matches) {
        $(".nav-desktop .nav-dropdown.active *").on("click", function () {
          $(".nav-dropdown").removeClass("active");
        });
      }

      // desktop nav
      $(".nav-desktop .nav-dropdown").on("click", function () {
        $(".nav-desktop .nav-dropdown.active").not(this).removeClass("active");
        $(this).toggleClass("active");
      });

      // mobile nav
      $(".nav-offcanvas .nav-dropdown > a").on("click", function () {
        var $parent = $(this).parent();

        if ($parent.hasClass('active')) {
          $parent.removeClass('active');
        } else {
          $parent.toggleClass("active");
        }
        // $(".nav-offcanvas .nav-dropdown.active")
        // .not($parent)
        // .removeClass("active");
      });

      var scrollContainer = $(window);
      var $body = $("body");

      $('.scroll-header-container').addClass('add-transition');

      scrollContainer.on("scroll load", function () {
        if (scrollContainer.scrollTop() >= 100) {
          $body.addClass("scroll-header-is-visible");
        } else {
          $body.removeClass("scroll-header-is-visible");
        }

        if (scrollContainer.scrollTop() >= 300) {
          $body.addClass("snav-is-visible");
          $('.luke-cta-container:not(.hide)').addClass('show');  
        } else {
          $body.removeClass("snav-is-visible");
        }
      });

      // cta-luke
      $('.luke-cta-container .cta-close-button').on('click', function() {
        $('.luke-cta-container').addClass('hide');        
        $('.luke-cta-container').removeClass('show'); 
      });

      $('.luke-cta-container .minimized').on('click', function() {        
        $('.luke-cta-container').removeClass('hide');   
        $('.luke-cta-container').addClass('show');
      });

    });

    // If pages are different (ie /mini-series) you can add a `.js-header` class to the section that you want to be adjusted to account for the header overlap becuase of the headers position absolute.

    // var $hero = $(".js-header");
    // var heroSpacing = parseInt($hero.css("margin-top"));
    // var heroStyle = "fill";

    // if (!$hero.length) {
    //   $hero = $("main").find("div, section").first();
    //   heroSpacing = parseInt($hero.css("padding-top"));
    // }

    // if ($hero.hasClass("billboard")) {
    //   heroStyle = "billboard";
    // }

    $(window).on("load ready", function () {

      // if (heroStyle == "fill") {
      //   heroSpacing = parseInt($hero.css("padding-top"));
      //   $hero.css(
      //     "padding-top",
      //     heroSpacing + $(".header").outerHeight() + "px"
      //   );
      // } else if (heroStyle == "billboard") {
      //   heroSpacing = parseInt($hero.css("margin-top"));
      //   $hero.css(
      //     "margin-top",
      //     heroSpacing + $(".header").outerHeight() + "px"
      //   );
      // }

    }); // window ready/load

    // on window load
    // ----------------------------------------------------
    // adjust the top padding of the first section to account for the absolutley positioned header size

    // $(window).on("resize", function () {
    //   $hero.css("padding-top", heroSpacing + $(".header").outerHeight() + "px");
    // });

  },
});
